import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import AddBrandModal from "./AddBrandModal";
import EditBrandModal from "./EditBrandModal";
import Notification from "../../components/Notification";
import {
  Badge,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;

const BrandList = () => {
  const history = useHistory();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentBrand, setCurrentBrand] = useState(null);
  const [brandToDelete, setBrandToDelete] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [clearForm, setClearForm] = useState(false);

  useEffect(() => {
    fetchBrands();
  }, []);

  const handleEdit = (brand) => {
    setCurrentCategory(brand);
    setShowEditModal(true);
  };

  const fetchBrands = async () => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.get(`${BASE_URL}brands`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setBrands(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleAddBrand = async (brand) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.post(`${BASE_URL}brands`, brand, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchBrands();
        setShowAddModal(false);
        setClearForm(true);
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };
  // edit
  const handleEditBrand = async (id, brand) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.put(`${BASE_URL}brands/${id}`, brand, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchBrands();
        setShowAddModal(false);
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleDelete = async (id) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.delete(`${BASE_URL}brands/${id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchBrands();
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }

      setBrandToDelete(null);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", type: "" });
  };

  const columns = [
    {
      field: "brand_title",
      renderHeader: () => <strong> {"Brand Name"} </strong>,
      width: 400,
    },
    {
      field: "action",
      renderHeader: () => <strong> {"Action"} </strong>,
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <EditOutlined
            variant="contained"
            color="success"
            onClick={() => {
              setCurrentBrand(params.row);
              setShowEditModal(true);
            }}
          />
          {/* <DeleteOutline onClick={() => handleDelete(params.row.id)} /> */}
          <DeleteOutline
            variant="contained"
            color="error"
            onClick={() => setBrandToDelete(params.row)}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="rgb(239, 127, 26)"
                  >
                    Brands
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "rgb(239, 127, 26)",
                      color: "white",
                    }}
                    onClick={() => setShowAddModal(true)}
                  >
                    Add Brand
                  </Button>
                </div>
                <Notification
                  open={notification.open}
                  message={notification.message}
                  type={notification.type}
                  handleClose={handleCloseNotification}
                />
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {/* <Box sx={{ height: 600, width: '100%' }}> */}
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : (
                  <>
                    {/* <Typography variant="subtitle1" gutterBottom>
                        Total Brands: {brands.length}
                      </Typography> */}
                    <DataGrid
                      rows={brands ? brands : []}
                      disableRowSelectionOnClick
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                    />
                    {currentBrand && (
                      <EditBrandModal
                        show={showEditModal}
                        handleClose={() => setShowEditModal(false)}
                        brand={currentBrand}
                        handleSave={handleEditBrand}
                      />
                    )}
                    <AddBrandModal
                      show={showAddModal}
                      handleClose={() => setShowAddModal(false)}
                      handleSave={handleAddBrand}
                      clearForm={clearForm}
                    />
                    <Dialog
                      open={!!brandToDelete}
                      onClose={() => setBrandToDelete(null)}
                    >
                      <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Are you sure you want to delete the brand "
                          {brandToDelete?.brand_title}"?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setBrandToDelete(null)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleDelete(brandToDelete.id)}
                          color="secondary"
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
                {/* </Box> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BrandList;
