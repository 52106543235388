// src/components/CategoryList.js
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import EditCategoryModal from "./EditCategoryModal";
import AddCategoryModal from "./AddCategoryModal";
import Notification from "../../components/Notification";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import {
  Badge,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

const CategoryList = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [clearForm, setClearForm] = useState(false);

  useEffect(() => {
    fetchCategories();
    fetchBrands();
  }, []);

  const fetchCategories = async () => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.get(`${BASE_URL}categories`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setCategories(response.data.data);
      setLoading(false);
    } catch (err) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };
  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", type: "" });
  };
  const fetchBrands = async () => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.get(`${BASE_URL}brands`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setBrands(response.data.data);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleDelete = async (id) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.delete(`${BASE_URL}categories/${id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category.id !== id)
      );
      setCategoryToDelete(null); // Close the dialog after deletion
      // setCategories(categories.filter(category => category.id !== id));
    } catch (err) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleEdit = (category) => {
    setCurrentCategory(category);
    setShowEditModal(true);
  };

  const handleSaveEdit = async (id, updatedCategory) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.put(
        `${BASE_URL}categories/${id}`,
        updatedCategory,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchCategories();
        setShowEditModal(false);
        setClearForm(true);
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
      // setCategories(categories.map(category => (category.id === id ? updatedCategory : category)));
      // setShowEditModal(false);
    } catch (err) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleSaveAdd = async (newCategory) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.post(`${BASE_URL}categories`, newCategory, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      // setCategories([...categories, response.data.data]);
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchCategories();
        setShowAddModal(false);
        setClearForm(true);
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
    } catch (err) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };
  let i = 1;
  const columns = [
    {
      field: "cat_name",
      renderHeader: () => <strong> {"Category Name "} </strong>,
      width: 300,
    },
    {
      field: "brand_title",
      renderHeader: () => <strong> {"Brand Name "} </strong>,
      width: 300,
    },
    {
      field: "cat_img",
      renderHeader: () => <strong> {"Category Image"} </strong>,
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            <img
              src={` ${IMG_URL}categories/${params.row.cat_img}`}
              alt={`${params.row.cat_img}`}
              style={{ width: 100, height: 100 }}
            />
          </div>
        );
      },
    },
    {
      field: "action",
      renderHeader: () => <strong> {"Action"} </strong>,
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <EditOutlined
            variant="contained"
            color="success"
            onClick={() => handleEdit(params.row)}
          />
          {/* <DeleteOutline onClick={() => handleDelete(params.row.id)} /> */}
          <DeleteOutline
            variant="contained"
            color="error"
            onClick={() => setCategoryToDelete(params.row)}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Container fluid>
        <Notification
          open={notification.open}
          message={notification.message}
          type={notification.type}
          handleClose={handleCloseNotification}
        />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography variant="h4" color="rgb(239, 127, 26)">
                    Categories
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "rgb(239, 127, 26)",
                      color: "white",
                    }}
                    onClick={() => setShowAddModal(true)}
                  >
                    Add Category
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {/* <Box sx={{ height: 600, width: '100%' }}> */}

                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : (
                  <>
                    <DataGrid
                      rows={categories || []}
                      disableRowSelectionOnClick
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                    />
                    {currentCategory && (
                      <EditCategoryModal
                        show={showEditModal}
                        handleClose={() => setShowEditModal(false)}
                        category={currentCategory}
                        handleSave={handleSaveEdit}
                        brands={brands ? brands : []}
                      />
                    )}
                    <AddCategoryModal
                      show={showAddModal}
                      handleClose={() => setShowAddModal(false)}
                      handleSave={handleSaveAdd}
                      brands={brands ? brands : []}
                      clearForm={clearForm}
                    />
                    <Dialog
                      open={!!categoryToDelete}
                      onClose={() => setCategoryToDelete(null)}
                    >
                      <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Are you sure you want to delete the category "
                          {categoryToDelete?.cat_name}"?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setCategoryToDelete(null)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleDelete(categoryToDelete.id)}
                          color="secondary"
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
                {/* </Box> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CategoryList;
