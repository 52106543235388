// src/components/LoginPage.js
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const LOGIN_API = process.env.REACT_APP_ADMIN_LOGIN_API;

const LoginPage = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState("");
  const notificationAlertRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const notify = (message, type) => {
    const options = {
      place: "bc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const validate = () => {
    const newErrors = {};
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!email.match(emailPattern)) {
      newErrors.email = "Enter a valid email";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (!password.match(passwordPattern)) {
      newErrors.password =
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      setIsDisabled(true);
      const response = await axios.post(`${LOGIN_API}`, { email, password });

      if (response.data.status === 1) {
        localStorage.setItem("auth_token", response.data.token);
        localStorage.setItem("users", JSON.stringify(response.data.data));
        notify("Login successful!", "success", "bc");
        setTimeout(() => {
          history.replace("/admin");
        }, 1000);
      } else {
        notify("Invalid email or password", "danger", "bc");
        setLoginError("Invalid email or password");
      }
    } catch (error) {
      notify("Login failed! Please try again.", "danger");
      setLoginError("Invalid email or password");
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "50px" }}>
        <Box display="flex" justifyContent="center" mb={2}>
          <img
            src={require("assets/img/logo-website.png")}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />{" "}
          {/* Add logo here */}
        </Box>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
          {loginError && (
            <Typography color="error" variant="body2">
              {loginError}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isDisabled}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;
