import axios from "axios";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Box,
} from "@mui/material";
// import { Progress } from "../../components/";
import CircularProgress from "@mui/material/CircularProgress";

const ImportProductModal = ({ show, handleClose, handleSave, isLoading }) => {
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const API_URL = process.env.REACT_APP_API_URL;
  //const [isLoading, setIsLoading] = useState(isLoading);
  // const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && !selectedFile.name.match(/\.(xls|xlsx)$/)) {
      setErrors({ file: "Please upload a valid Excel file (.xls or .xlsx)" });
      setFile(null);
    } else {
      setErrors({});
      setFile(selectedFile);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!file) newErrors.file = "Excel file is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!validate()) return;
      // setLoading(true);
      //setIsLoading(true);
      let formData = new FormData();
      formData.append("file", file);
      handleSave(formData);
      ////handleClose();
      //setIsLoading(false);
    } catch (error) {
      console.error("Error importing products:", error);
    }
  };

  return (
    <Dialog open={show} onClose={handleClose} className="" maxWidth="md">
      <DialogTitle color="rgb(239, 127, 26)" align="center">
        Import Products
        <a
          href={`${API_URL}export_reports/sample_data.xlsx`}
          download="sample_data.xlsx"
          target="_blank"
          rel="noreferrer"
        >
          <Button color="secondary"> Download Sample File </Button>
        </a>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="dense" error={!!errors.file}>
            <FormLabel>Upload Excel File Only</FormLabel>
            <input
              required
              type="file"
              accept=".xls,.xlsx"
              onChange={handleFileChange}
            />
            <FormHelperText>{errors.file}</FormHelperText>
          </FormControl>
          <div align="center">{isLoading ? <CircularProgress /> : ""}</div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          disabled={isLoading}
          style={{ backgroundColor: "#6c757d", color: "white" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="success"
          style={{ backgroundColor: "rgb(239, 127, 26)", color: "white" }}
        >
          {/* {isLoading ? 'Importing...' : 'Import'} */}
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportProductModal;
