import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import moment from "moment"; // Importing moment

import "react-notification-alert/dist/animate.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;
const API_URL = process.env.REACT_APP_API_URL;
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.get(`${BASE_URL}contact_us`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setContacts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setTimeout(10000000);
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <strong> {"Name"} </strong>,
      width: 200,
    },
    {
      field: "number",
      renderHeader: () => <strong> {"Contact No"} </strong>,
      width: 200,
    },
    {
      field: "machine_brand",
      renderHeader: () => <strong> {"Machine Brand"} </strong>,
      width: 200,
    },
    {
      field: "remarks",
      renderHeader: () => <strong> {"Remarks"} </strong>,
      width: 400,
    },
    {
      field: "created ",
      renderHeader: () => <strong> {"Created"} </strong>,
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Typography variant="h4" gutterBottom color="rgb(239, 127, 26)">
        Contact Us
      </Typography>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataGrid
          rows={contacts ? contacts : []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          pageSizeOptions={[10]}
        />
      )}
    </Box>
  );
};

export default ContactList;
