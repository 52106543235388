// src/components/AddBrandModal.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormHelperText,
} from "@mui/material";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;
const AddBrandModal = ({ show, handleClose, handleSave, clearForm }) => {
  const [brand_title, setBrandTittle] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setBrandTittle("");
  }, [clearForm]);

  const validate = () => {
    const newErrors = {};
    if (!brand_title) newErrors.brand_title = "Brand Name is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Base Url ", BASE_URL);
    setBrandTittle("");
    if (!validate()) return;
    const payload = { brand_title: brand_title };
    try {
      handleSave(payload);
      // handleClose();
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle color="rgb(239, 127, 26)" align="center">
        {" "}
        Add Brand
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            margin="dense"
            name="brand_title"
            label="Brand Name"
            type="text"
            fullWidth
            value={brand_title}
            onChange={(event) => setBrandTittle(event.target.value)}
            error={!!errors.brand_title}
            helperText={errors.brand_title}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          style={{ backgroundColor: "#6c757d", color: "white" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="success"
          style={{ backgroundColor: "rgb(239, 127, 26)", color: "white" }}
        >
          Add Brand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBrandModal;
