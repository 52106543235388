import axios from "axios";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormHelperText,
} from "@mui/material";

const AddProductModal = ({
  show,
  handleClose,
  handleSave,
  brands,
  product = {},
}) => {
  const [categories, setCategories] = useState([]);
  const [brand_code, setBrand] = useState(product.brand_code || "");
  const [cat_code, setCatCode] = useState(product.cat_code || "");
  const [prod_name, setProdName] = useState(product.prod_name || "");
  const [prod_code, setProdCode] = useState(product.prod_code || "");
  const [prod_img, setImage] = useState({
    preview: product.prod_img || "",
    data: "",
  });
  const [errors, setErrors] = useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    if (brand_code) {
      fetchCategories(brand_code);
    }
  }, [brand_code]);

  const fetchCategories = async (brandId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}categories?brand=${brandId}`
      );
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const validate = () => {
    const newErrors = {};
    if (!brand_code) newErrors.brand_code = "Brand is required";
    if (!cat_code) newErrors.cat_code = "Category is required";
    if (!prod_name) newErrors.prod_name = "Product name is required";
    if (!prod_code) newErrors.prod_code = "Product code is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    let formData = new FormData();
    if (prod_img.data) {
      formData.append("file", prod_img.data);
    }
    formData.append("brand_code", brand_code);
    formData.append("cat_code", cat_code);
    formData.append("prod_code", prod_code);
    formData.append("prod_name", prod_name);

    try {
      // if (product.id) {
      // //   await axios.put(`${BASE_URL}/products/${product.id}`, formData, {
      // //     headers: {
      // //       'Content-Type': 'multipart/form-data',
      // //     },
      // //   });
      // // } else {
      // //   await axios.post(`${BASE_URL}/products`, formData, {
      // //     headers: {
      // //       'Content-Type': 'multipart/form-data',
      // //     },
      // //   });

      //  }
      handleSave(product.id, formData, cat_code);
      // handleClose();
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle color="rgb(239, 127, 26)" align="center">
        {product.id ? "Edit Product" : "Add Product"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="dense" error={!!errors.brand_code}>
            <InputLabel id="brand-label">Select Brand</InputLabel>
            <Select
              required
              labelId="brand-label"
              name="brand_code"
              value={brand_code}
              onChange={(event) => setBrand(event.target.value)}
            >
              {brands?.map((brand) => (
                <MenuItem key={brand.brand_code} value={brand.brand_code}>
                  {brand.brand_title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.brand_code}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="dense" error={!!errors.cat_code}>
            <InputLabel id="cat-label">Select Category</InputLabel>
            <Select
              required
              labelId="cat-label"
              name="cat_code"
              value={cat_code}
              onChange={(event) => setCatCode(event.target.value)}
            >
              {categories?.map((category) => (
                <MenuItem key={category.cat_code} value={category.cat_code}>
                  {category.cat_name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.cat_code}</FormHelperText>
          </FormControl>
          <TextField
            required
            margin="dense"
            name="prod_name"
            label="Product Name"
            type="text"
            fullWidth
            value={prod_name}
            onChange={(event) => setProdName(event.target.value)}
            error={!!errors.prod_name}
            helperText={errors.prod_name}
          />
          <TextField
            required
            margin="dense"
            name="prod_code"
            label="Product Code"
            type="text"
            fullWidth
            value={prod_code}
            onChange={(event) => setProdCode(event.target.value)}
            error={!!errors.prod_code}
            helperText={errors.prod_code}
          />

          <FormControl fullWidth margin="dense" error={!!errors.prod_img}>
            <FormLabel>Upload Product Image</FormLabel>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <FormHelperText>{errors.prod_img}</FormHelperText>
          </FormControl>
          {prod_img.preview && (
            <img
              src={`${IMG_URL}products/${prod_img.preview}`}
              alt="Product Preview"
              style={{
                width: "180px",
                height: "180px",
                marginTop: "0px",
                float: "right",
              }}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="success"
          style={{ backgroundColor: "#6c757d", color: "white" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="success"
          style={{ backgroundColor: "rgb(239, 127, 26)", color: "white" }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductModal;
