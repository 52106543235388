import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormHelperText,
} from "@mui/material";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;

const EditCategoryModal = ({
  show,
  handleClose,
  handleSave,
  brands,
  category,
}) => {
  const [brand_code, setBrand] = useState(category.brand_code);
  const [cat_name, setCatName] = useState(category.cat_name);
  const [cat_img, setImage] = useState({
    preview: `${IMG_URL}/categories/${category.cat_img}`,
    data: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (category) {
      setBrand(category.brand_code);
      setCatName(category.cat_name);
      setImage({
        preview: `${IMG_URL}/categories/${category.cat_img}`,
        data: "",
      });
    }
  }, [category]);

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const validate = () => {
    const newErrors = {};
    if (!brand_code) newErrors.brand_code = "Brand is required";
    if (!cat_name) newErrors.cat_name = "Category name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    let formData = new FormData();
    if (cat_img.data) {
      formData.append("file", cat_img.data);
    }
    formData.append("brand_code", brand_code);
    formData.append("cat_name", cat_name);

    try {
      // await axios.put(`${BASE_URL}/categories/${category.id}`, formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      handleSave(category.id, formData);
      handleClose();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Edit Category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please update the details of the category.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="dense" error={!!errors.brand_code}>
            <InputLabel id="brand-label">Select Brand</InputLabel>
            <Select
              required
              labelId="brand-label"
              name="brand_code"
              value={brand_code}
              onChange={(event) => setBrand(event.target.value)}
            >
              {brands.map((brand) => (
                <MenuItem key={brand.brand_code} value={brand.brand_code}>
                  {brand.brand_title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.brand_code}</FormHelperText>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            name="cat_name"
            label="Category Name"
            type="text"
            fullWidth
            value={cat_name}
            onChange={(event) => setCatName(event.target.value)}
            error={!!errors.cat_name}
            helperText={errors.cat_name}
          />

          <FormControl fullWidth margin="dense" error={!!errors.prod_img}>
            <FormLabel>Upload Product Image</FormLabel>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <FormHelperText>{errors.prod_img}</FormHelperText>
          </FormControl>
          {cat_img.preview && (
            <img
              src={`${IMG_URL}categories/${cat_img.preview}`}
              alt="Category Preview"
              style={{
                width: "180px",
                height: "180px",
                marginTop: "0px",
                float: "right",
              }}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          style={{ backgroundColor: "#6c757d", color: "white" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="success"
          style={{ backgroundColor: "rgb(239, 127, 26)", color: "white" }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategoryModal;
