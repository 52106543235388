import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import moment from "moment"; // Importing moment

import "react-notification-alert/dist/animate.css";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.get(`${BASE_URL}users_enquiry`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setContacts(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <strong> {"User Name"} </strong>,
      width: 200,
    },
    {
      field: "phone_no",
      renderHeader: () => <strong> {"Contact No"} </strong>,
      width: 200,
    },
    {
      field: "company_name",
      renderHeader: () => <strong> {"Company Name"} </strong>,
      width: 200,
    },
    {
      field: "machine_parts",
      renderHeader: () => <strong> {"Machine Parts"} </strong>,
      width: 200,
    },
    {
      field: "brand_title",
      renderHeader: () => <strong> {"Brand Name"} </strong>,
      width: 200,
    },
    {
      field: "cat_name",
      renderHeader: () => <strong> {"Category Name"} </strong>,
      width: 200,
    },
    {
      field: "prod_name",
      renderHeader: () => <strong> {"Product Name"} </strong>,
      width: 200,
    },
    {
      field: "quantity",
      renderHeader: () => <strong> {"Quantity"} </strong>,
      width: 200,
    },

    {
      field: "created ",
      renderHeader: () => <strong> {"Created"} </strong>,
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Typography variant="h4" gutterBottom color="rgb(239, 127, 26)">
        User Enquiries
      </Typography>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataGrid
          rows={contacts}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      )}
    </Box>
  );
};

export default ContactList;
