import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AdminLayout from "layouts/Admin.js";
import LoginPage from "components/LoginPage";
import Logout from "components/LogOut";
import PrivateRoute from "components/PrivateRoute";
import PrivacyPolicy from "components/PrivacyPolicy";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/privacy_policy_web" render={(props) => <PrivacyPolicy />} />
      <Route path="/login" render={(props) => <LoginPage />} />
      <Route path="/logout" render={(props) => <Logout />} />
      <PrivateRoute path="/admin" component={AdminLayout} />
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </BrowserRouter>
);
