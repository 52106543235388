// src/components/Logout.js
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("auth_token");
    history.push("/login");
  }, [history]);

  return null;
};

export default Logout;
