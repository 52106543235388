import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField, FormControl, FormLabel, FormHelperText } from '@mui/material';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;

const EditBrandModal = ({ show, handleClose, handleSave, brand }) => {
  const [brand_title, setBrandTitle] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (brand) {
      setBrandTitle(brand.brand_title);
    }
  }, [brand]);


  const validate = () => {
    const newErrors = {};
    if (!brand_title) newErrors.brand_title = 'Brand Title is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = { brand_title: brand_title };
    try {
   
      handleSave(brand.id,payload);
     // handleClose();
    } catch (error) {
      console.error('Error updating brand:', error);
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle  color='rgb(239, 127, 26)' align='center' >Edit Brand</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            margin="dense"
            name="brand_title"
            label="Brand Name"
            type="text"
            fullWidth
            value={brand_title}
            onChange={(event) => setBrandTitle(event.target.value)}
            error={!!errors.brand_title}
            helperText={errors.brand_title}
          />
          
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary" style={{ backgroundColor: '#6c757d', color: 'white' }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="success" style={{ backgroundColor: 'rgb(239, 127, 26)', color: 'white' }}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBrandModal;
