
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import BrandList from "views/Brands/Brands";
import CategoryList from "views/Category/Categories";
import ProductList from  "views/Products/ProductList";
import Contactus from "views/ContactUs";
import Users from "views/Users";

const dashboardRoutes = [
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-alien-33",
  //   component: Upgrade,
  //   layout: "/admin"
  // },
  {
    path: "/dashboard",
    name: "Enquiries",
    icon: "nc-icon nc-bell-55",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users ",
    icon: "nc-icon nc-circle-09",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/brands",
    name: "Brands",
    icon: "nc-icon nc-align-left-2",
    component: BrandList,
    layout: "/admin"
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "nc-icon nc-align-left-2",
    component: CategoryList,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "Products",
    icon: "nc-icon nc-align-left-2",
    component: ProductList,
    layout: "/admin"
  },
  {
    path: "/contact-us",
    name: "Contact us",
    icon: "nc-icon nc-align-left-2",
    component: Contactus,
    layout: "/admin"
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "nc-icon nc-circle-09",
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
