import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Link,
} from "@mui/material";
import {
  Badge,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";
import ImportProductModal from "./ImportProductModal"; // Import the ImportProductModal component
import Notification from "../../components/Notification";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;
const API_URL = process.env.REACT_APP_API_URL;

const ProductList = () => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false); // State for import modal
  const [currentProduct, setCurrentProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clearForm, setClearForm] = useState(false);
  const [downloadLink, setdownloadLink] = useState("");
  const [url, setUrl] = useState("");
  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", type: "" });
  };

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    fetchProducts();
    fetchBrands();
  }, []);

  const fetchProducts = async () => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.get(`${BASE_URL}products`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setProducts(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const fetchBrands = async () => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.get(`${BASE_URL}brands`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setBrands(response.data.data);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleAddProduct = async (product, cat_code) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.post(
        `${BASE_URL}products?folder=${cat_code}`,
        product,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchProducts();
        setShowAddModal(false);
        setClearForm(true);
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleEditProduct = async (id, product, cat_code) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.put(
        `${BASE_URL}products/${id}?folder=${cat_code}`,
        product,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchProducts();
        setShowEditModal(false);
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      const response = await axios.delete(`${BASE_URL}products/${id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.data?.status == 1) {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchProducts();
      } else {
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "error",
        });
      }
      setProductToDelete(null);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const handleImportProducts = async (formData) => {
    try {
      const jwtToken = localStorage.getItem("auth_token");
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}products/import`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.data?.status == 1) {
        setIsLoading(false);
        setNotification({
          open: true,
          message: `${response.data?.message}`,
          type: "success",
        });
        fetchProducts();
        setShowImportModal(false);
      } else {
        console.log("url in PL", response.data?.reportFile);
        const message = response.data?.message;
        setIsLoading(false);

        setUrl(`${API_URL}export_reports/${response.data?.reportFile}`);
        setNotification({
          open: true,
          message: `${message}`,
          type: "error",
        });
        setShowImportModal(false);
      }

      // fetchProducts();
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");

      setLoading(false);
      return;
    }
  };

  const saveFile = (url) => {
    fileSaver.saveAs(url, "import_report.xlsx");
  };
  //const handleCloseEvent = async () => {};

  const columns = [
    {
      field: "prod_name",
      renderHeader: () => <strong> {"Product Name"} </strong>,
      width: 300,
    },
    {
      field: "prod_code",
      renderHeader: () => <strong> {"Product Code "} </strong>,
      width: 200,
    },
    {
      field: "cat_name",
      renderHeader: () => <strong> {"Product Category "} </strong>,
      width: 300,
    },
    {
      field: "brand_title",
      renderHeader: () => <strong> {"Product Brand"} </strong>,
      width: 300,
    },
    {
      field: "prod_img",
      renderHeader: () => <strong> {"Image "} </strong>,
      width: 100,
      renderCell: (params) => {
        console.log(params);
        return (
          <div>
            <img
              src={` ${IMG_URL}products/${params.row.cat_code}/${params.row.prod_img}`}
              style={{ width: "100%", "object-fit": "contain" }}
              alt={`${params.row.prod_img}`}
            />
          </div>
        );
      },
    },
    {
      field: "actions",
      renderHeader: () => <strong> {"Actions "} </strong>,
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <EditOutlined
            variant="contained"
            onClick={() => {
              setCurrentProduct(params.row);
              setShowEditModal(true);
            }}
          />
          <DeleteOutline
            variant="contained"
            color="error"
            onClick={() => setProductToDelete(params.row)}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <Notification
        open={notification.open}
        message={notification.message}
        type={notification.type}
        url={url}
      />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="rgb(239, 127, 26)"
                  >
                    Products
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    gap: "5px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "rgb(239, 127, 26)",
                      color: "white",
                    }}
                    onClick={() => setShowAddModal(true)}
                  >
                    Add Product
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: "#6c757d", color: "white" }}
                    onClick={() => setShowImportModal(true)}
                  >
                    Import Products
                  </Button>

                  <a
                    href={`${API_URL}export_reports/sample_data.xlsx`}
                    download="sample_data.xlsx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button color="secondary"> Download Sample File </Button>
                  </a>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              {/* <Box sx={{ height: 600, width: '100%' }}> */}

              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>Error: {error}</p>
              ) : (
                <DataGrid
                  rowHeight={150}
                  rows={products ? products : []}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                />
              )}
              <AddProductModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                handleSave={handleAddProduct}
                brands={brands ? brands : []}
                clearForm={clearForm}
              />
              {currentProduct && (
                <EditProductModal
                  show={showEditModal}
                  handleClose={() => setShowEditModal(false)}
                  product={currentProduct}
                  handleSave={handleEditProduct}
                  brands={brands ? brands : []}
                />
              )}

              <Dialog
                open={!!productToDelete}
                onClose={() => setProductToDelete(null)}
              >
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete the product "
                    {productToDelete?.prod_name}"?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setProductToDelete(null)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleDeleteProduct(productToDelete.id)}
                    color="secondary"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>

              <ImportProductModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                handleSave={handleImportProducts}
                isLoading={isLoading}
              />
              {/* </Box> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductList;
