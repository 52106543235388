import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment"; // Importing moment

import "react-notification-alert/dist/animate.css";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const jwtToken = localStorage.getItem("auth_token");
  const history = useHistory();

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}users`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setContacts(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Invalid Authentication");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("users");
      history.replace("/login");
      setLoading(false);
      return;
    }
  };

  const handleToggleActive = (id, currentStatus) => {
    setSelectedUser(id);
    setSelectedStatus(currentStatus);
    setDialogOpen(true);
  };

  const confirmToggleActive = async () => {
    try {
      await axios.patch(
        `${BASE_URL}users/status_change/${selectedUser}`,
        {
          isActive: !selectedStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      fetchContacts(); // Refresh the list after updating
    } catch (error) {
      console.error("Failed to update user status", error);
    } finally {
      setDialogOpen(false);
    }
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <strong> {"User Name"} </strong>,
      width: 200,
    },
    {
      field: "phone_no",
      renderHeader: () => <strong> {"Contact No"} </strong>,
      width: 200,
    },
    {
      field: "email",
      renderHeader: () => <strong> {"Email"} </strong>,
      width: 200,
    },
    {
      field: "company_name",
      renderHeader: () => <strong> {"Company Name"} </strong>,
      width: 200,
    },
    {
      field: "machine_parts",
      renderHeader: () => <strong> {"Machine Parts"} </strong>,
      width: 200,
    },
    {
      field: "created",
      renderHeader: () => <strong> {"Created"} </strong>,
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const isActive = params.row.status == "1" ? true : false;
        return (
          <Switch
            checked={isActive}
            onChange={() => handleToggleActive(params.row.id, isActive)}
            color="success"
          />
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Typography variant="h4" gutterBottom color="rgb(239, 127, 26)">
        Users{" "}
      </Typography>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataGrid
          slots={{
            toolbar: CustomToolbar,
          }}
          rows={contacts}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          Confirm Status Change
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to{" "}
            {selectedStatus ? "deactivate" : "activate"} this user?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmToggleActive} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContactList;
