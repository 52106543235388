import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import { disposeEmitNodes } from "typescript";

const BASE_URL = process.env.REACT_APP_API_URL;

const PrivacyPolicy = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await axios.get(`${BASE_URL}page/privacy_policy_web`);
        console.log("response", response.data.data[0].page_contents);
        setContent(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching privacy policy content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContents();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Paper elevation={3} style={{ padding: "20px", marginTop: "50px" }}>
        <Box display="flex" justifyContent="center" mb={2}>
          <img
            src={require("assets/img/logo-website.png")}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </Box>
        <Typography
          component="h1"
          variant="h5"
          display="flex"
          justifyContent="center"
        >
          {content?.page_title}
        </Typography>
        <Box component="div" noValidate sx={{ mt: 1 }}>
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content?.page_contents }} />
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
